function getCurrentSeason() {
    const month = new Date().getMonth() + 1;

    if (month >= 3 && month <= 5) {
        return "spring";
    } else if (month >= 6 && month <= 8) {
        return "summer";
    } else if (month >= 9 && month <= 11) {
        return "fall";
    } else {
        return "winter";
    }
}
function setSeasonTheme() {
    const season = getCurrentSeason();
    document.body.classList.add(`${season}`);
    if (document.body) {
        document.body.style.setProperty('--theme-color', `var(--color-${season})`);
    }
}

window.requestAnimationFrame(() => {
    setSeasonTheme()
})
